import './bootstrap';
import {createApp, h} from 'vue';
import {createInertiaApp, router} from '@inertiajs/vue3'
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import * as faSolidIcons from '@fortawesome/free-solid-svg-icons';
import * as faRegularIcons from '@fortawesome/free-regular-svg-icons';
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css';
import ContextMenu from '@imengyu/vue3-context-menu';
import "vue-progressive-image/dist/style.css";
import * as Sentry from "@sentry/vue";
import WebApp from "@twa-dev/sdk";
import {useSessionStorage} from '@vueuse/core';
import {createPinia} from 'pinia';
import VueApexCharts from "vue3-apexcharts";

// load fontawesome icons
library.add(faSolidIcons.faCircleNotch);
library.add(faSolidIcons.faXmark);
library.add(faSolidIcons.faMagnifyingGlass);
library.add(faSolidIcons.faBoxesStacked);
library.add(faSolidIcons.faShare);
library.add(faSolidIcons.faEllipsisVertical);
library.add(faSolidIcons.faLink);
library.add(faSolidIcons.faCode);
library.add(faSolidIcons.faInfo);
library.add(faSolidIcons.faTableCellsLarge);
library.add(faRegularIcons.faCircleQuestion);
library.add(faRegularIcons.faClock);
library.add(faRegularIcons.faStar);
library.add(faRegularIcons.faPaperPlane);
library.add(faRegularIcons.faTrashCan);
library.add(faRegularIcons.faFaceSadTear);
library.add(faRegularIcons.faKeyboard);

// build inertia app
function inertiaRoutingInstrumentation(
    customStartTransaction,
    startTransactionOnPageLoad = true,
    startTransactionOnLocationChange = true,
) {
    let activeTransaction;
    let name;
    if (startTransactionOnPageLoad) {
        name = route().current();
        activeTransaction = customStartTransaction({
            name,
            op: 'pageload',
            metadata: {
                source: 'route',
            },
        });
    }

    if (startTransactionOnLocationChange) {
        router.on('before', (_to, _from) => {
            if (activeTransaction) {
                activeTransaction.finish();
            }

            const newName = route().current();

            if (newName !== name) {
                activeTransaction = customStartTransaction({
                    name: newName,
                    op: 'navigation',
                    metadata: {
                        source: 'route',
                    },
                });
            }
        });

        router.on('finish', () => {
            activeTransaction.setName(route().current(), 'route');
        });
    }
}

function getInitData(inertiaInitData){
    if(WebApp.initData){
        return WebApp.initData;
    }

    const savedInitData = useSessionStorage('initData', inertiaInitData);
    return savedInitData.value;
}

createInertiaApp({
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {

        window.axios.defaults.headers.common['X-Init-Data'] = getInitData(props.initialPage.props.inertiaInitData);

        const app = createApp({ render: () => h(App, props) })
            .component('font-awesome-icon', FontAwesomeIcon)
            .use(createPinia())
            .use(ContextMenu)
            .use(VueApexCharts)
            .use(plugin);

        // disable sentry in development
        if (process.env.NODE_ENV !== 'development') {
            Sentry.init({
                app,
                dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
                environment: import.meta.env.VITE_APP_ENV,
                integrations: [
                    new Sentry.BrowserTracing({
                        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                        tracePropagationTargets: ["localhost", import.meta.env.VITE_APP_URL, /^\//,],
                        routingInstrumentation: inertiaRoutingInstrumentation,
                        _experiments: {
                            enableInteractions: true,
                        }
                    }),
                    new Sentry.Replay(),
                ],
                // Performance Monitoring
                tracesSampleRate: 1.0, // Capture 100% of the transactions
                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });
        }

        app.mount(el);

        if (process.env.NODE_ENV !== 'development') {
            Sentry.setContext("WebApp", {
                version: WebApp.version,
                platform: WebApp.platform,
                backgroundColor: WebApp.backgroundColor,
                colorScheme: WebApp.colorScheme,
                headerColor: WebApp.headerColor,
                isExpanded: WebApp.isExpanded,
                themeParams: WebApp.themeParams,
            });

            Sentry.setContext("InitData", Object.fromEntries(new URLSearchParams(getInitData(props.initialPage.props.inertiaInitData))));
        }

        return app;
    },
    progress: {
        color: '#056104',
    },
});
